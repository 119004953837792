<template>
  <div class="ml-5">
    <div class="d-flex justify-space-between">
      <!-- <v-text-field v-model="search" prepend-inner-icon="mdi-magnify" label="Search" solo-inverted dense clearable
        single-line hide-details class="mr-5"></v-text-field> -->
      <!-- <v-btn @click="alert('Hello')">Open Alert</v-btn> -->
      <v-menu :close-on-content-click="false" open-on-hover bottom offset-y>
        <template v-slot:activator="{ on }">
          <v-btn small color="primary" dark v-on="on">
            <v-icon small class="mr-1">mdi-numeric-1-box-multiple</v-icon>Versions<v-icon>mdi-chevron-down</v-icon>
          </v-btn>
        </template>

        <v-list dense>
          <v-list-group color="" v-for="transcription in versions" :key="transcription.transcriptionId" no-action
            :value="true" xprepend-icon="mdi-account-circle">
            <template v-slot:activator>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    Transcription <small>{{ transcription.transcription.transcriptionId }}</small>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ dayjs(transcription.transcription.created).format('MMM D, YYYY hh:mm a') }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>

            <v-list-item class="pl-5" v-for="version in transcription.versions" :key="version.versionNumber"
              @click="selectVersion(transcription.transcription.transcriptionId, version.versionNumber)">
              <v-list-item-icon class="mr-3">
                <v-icon small color="yellow" class="mt-2" title="Published Version"
                  v-if="preferredVersion.version === version.versionNumber && preferredVersion.transcriptionId === transcription.transcription.transcriptionId && !transcription.transcription.inactive">
                  mdi-star
                </v-icon>
                <v-icon class="mt-2 hidden" small v-else>
                  mdi-square
                </v-icon>

                <v-icon small color="green" class="mt-2 mr-1" title="Music Notched"
                  v-if="version.proto && version.proto.isNotched">mdi-music-off</v-icon>
                <v-icon class="mt-2 hidden" small v-else>
                  mdi-square
                </v-icon>

                <v-icon color="secondary" class="mt-2" title="Selected"
                  v-if="transcription.transcription.transcriptionId === selectedVersion.transcriptionId && version.versionNumber === selectedVersion.version">
                  mdi-chevron-right
                </v-icon>
                <v-icon class="mt-2 hidden" v-else>
                  mdi-square
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Version {{ version.versionNumber }}</v-list-item-title>

                <v-list-item-subtitle>{{ dayjs(version.updated).format('MMM D, YYYY hh:mm a') }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </v-list>
      </v-menu>

      <!-- <v-switch v-model="tableMode" label="Table View" hide-details inset dense class="my-0 mr-5"></v-switch> -->

      <v-btn :dark="items.length > 0" small color="primary" :loading="speakersLoading" :disabled="!items.length"
        @click="openSpeakerEditor">
        <v-icon class="mr-1" small>mdi-account-voice</v-icon>Speakers
      </v-btn>

      <v-menu v-model="menus.find" :close-on-content-click="false" bottom offset-y min-width="400" max-width="400">
        <template v-slot:activator="{ on }">
          <v-btn dark small color="primary" v-on="on">
            <v-icon class="mr-1" small>mdi-magnify</v-icon>Replace
          </v-btn>
        </template>

        <v-card>
          <div class="text-right">
            <v-btn icon @click="menus.find = false" class="mt-1 mr-1">
              <v-icon small>mdi-close</v-icon>
            </v-btn>
          </div>
          <v-card-text class="pt-1">
            <v-text-field
              v-model="findReplace.find"
              prepend-inner-icon="mdi-magnify"
              label="Find"
              color="secondary"
              hide-details
              dense
              outlined
              class="mb-2"
              @keydown="handleFindReplaceKeydown"
            ></v-text-field>

            <v-text-field
              v-model="findReplace.replace"
              prepend-inner-icon="mdi-swap-horizontal"
              label="Replace With"
              color="secondary"
              hide-details
              dense
              outlined
              class="mb-2"
              @keydown="handleFindReplaceKeydown"
            ></v-text-field>

            <v-checkbox
              v-model="findReplace.caseSensitive"
              append-icon="mdi-format-letter-case-upper"
              label="Match Case"
              color="secondary"
              dense
              hide-details
              class="mt-0 mb-4"
            ></v-checkbox>

            <div class="d-flex justify-space-between align-center mt-2">
              <div>
                <v-btn small dark color="accent" :disabled="!hasMatches" @click="replaceOne">
                  Replace
                </v-btn>
                <v-btn small dark color="accent2" :disabled="!hasMatches" @click="replaceAll" class="ml-2">
                  Replace All
                </v-btn>
              </div>
              <div class="text-caption">
                {{ getMatchCountText() }}
              </div>
            </div>

            <v-list dense class="mt-4 search-results">
              <v-list-item
                v-for="(result, i) in findReplace.results"
                :key="i"
                :class="{'current-match': i === findReplace.currentMatchIndex}"
                @click="selectMatch(i)"
              >
                <v-list-item-content>
                  <v-list-item-title class="text-caption">
                    {{ getTimeCode(result.caption.start) }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <span v-html="highlightMatches(result)"></span>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>

            <small><em>Found {{ findReplace.results.length }} {{ findReplace.results.length === 1 ? 'occurrence' :
              'occurrences'
            }}</em></small>

          </v-card-text>
        </v-card>
      </v-menu>

      <v-btn :dark="isDirty" small color="primary" :disabled="!isDirty" @click="ccSave()">
        <v-icon class="mr-1" small>mdi-floppy</v-icon>Save
      </v-btn>

      <v-btn dark small color="success" v-if="showCCPublish" @click="ccApprove" :loading="ccLoading">
        <v-icon class="mr-1">mdi-closed-caption</v-icon>Publish
      </v-btn>

      <v-btn dark small color="error" v-if="showCCUnpublish" @click="ccReject" :loading="ccLoading">
        <v-icon class="mr-1">mdi-closed-caption</v-icon>Unpublish
      </v-btn>
    </div>

    <v-data-table v-model="selectedRows" :headers="headers" :items="itemsLimited" item-key="start" :search="search"
      :loading="loading" show-selectx disable-sort dense :items-per-page="-1" class="mt-3" id="virtual-scroll-table"
      @dblclick:row="selectCaption" v-scroll:#virtual-scroll-table="onScroll" v-if="tableMode">
      <template v-if="start > 0" v-slot:body.prepend>
        <tr>
          <td :colspan="headers.length" :style="'padding-top:' + startHeight + 'px'"></td>
        </tr>
      </template>
      <template v-if="start + perPage < this.items.length" v-slot:body.append>
        <tr>
          <td :colspan="headers.length" :style="'padding-top:' + endHeight + 'px'"></td>
        </tr>
      </template>

      <template v-slot:item.start="{ item }">
        {{ getTimeCode(item.start) }}
      </template>

      <template v-slot:item.end="{ item }">
        {{ getTimeCode(item.end) }}
      </template>

      <template v-slot:item.voice="props">
        <v-edit-dialog :return-value.sync="props.item.voice" large persistent>
          {{ getSpeaker(props.item.voice) }}
          <template v-slot:input>
            <v-select v-model="props.item.voice" :items="speakerNames" label="Speakers" outlined class="mt-5"></v-select>
          </template>
        </v-edit-dialog>
      </template>

      <template v-slot:item.text="props">
        <v-edit-dialog :return-value.sync="props.item.text" large persistent @save="saveItem">
          <span v-html="props.item.text"></span>
          <template v-slot:input>
            <v-textarea v-model="props.item.text" :rules="[]" label="Edit Caption" rows="3" counter outlined class="mt-5">
            </v-textarea>
          </template>
        </v-edit-dialog>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon small @click="deleteItemConfirm(item)"> mdi-delete </v-icon>
      </template>
    </v-data-table>

    <TranscriptEditor :items="itemsTranscript" :speakerNames="speakerNames" :currentTime="currentTime" :loading="loading"
      :isDirtyProp="isDirty" @seekreq="seekToCaption" @isDirty="transcriptDirtyChanged" v-else />

    <v-dialog v-model="dialogs.speakerEditor.state" max-width="700">
      <v-card>
        <v-toolbar dark dense color="primary">
          <v-toolbar-title>
            <v-icon class="mr-2">mdi-account-voice</v-icon>Edit Speaker Names
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogs.speakerEditor.state = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-5">
          <p>Edit the available speaker names below, if they are known.</p>

          <v-list>
            <v-list-item v-for="(speakerName, index) in dialogs.speakerEditor.speakerNames" :key="index">
              <v-list-item-content class="py-2">
                <div class="d-flex">
                  <v-select v-model="dialogs.speakerEditor.speakerNamesEdited[index]" :items="guestsAndHosts"
                    :label="`Speaker ${index + 1}`" color="secondary" dense outlined hide-details class="mr-5"></v-select>

                  <v-text-field v-model="dialogs.speakerEditor.speakerNamesCustom[index]" 
                    :label="`Speaker ${index + 1}`"
                    color="secondary" 
                    dense 
                    outlined 
                    hide-details
                    maxlength="32"
                    counter
                    v-if="dialogs.speakerEditor.speakerNamesEdited[index] === '-- Custom --'"></v-text-field>
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialogs.speakerEditor.state = false">Cancel</v-btn>
          <v-btn text color="secondary" @click="saveSpeakers">Assign</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogs.confirmDelete.state" max-width="500">
      <v-card>
        <v-card-title>Delete Caption</v-card-title>
        <v-card-text v-if="dialogs.confirmDelete.item">
          <p class="subtitle-1">
            Are you sure you want to delete the following caption?
          </p>

          <v-sheet color="grey darken-3" class="subtitle-1 pa-5">
            <strong>{{ getSpeaker(dialogs.confirmDelete.item.voice) }} |
              {{ getTimeCode(dialogs.confirmDelete.item.start) }}</strong>
            <div>{{ stripHTML(dialogs.confirmDelete.item.text) }}</div>
          </v-sheet>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialogs.confirmDelete.state = false">Cancel</v-btn>
          <v-btn text color="error" @click="deleteItem(dialogs.confirmDelete.item)">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- <Alert v-model="dialogs.alert.state" :message="dialogs.alert.message" /> -->
    <!-- <Alert :state="dialogs.alert.state" :message="dialogs.alert.message"></Alert> -->

    <v-dialog v-model="dialogs.alert.state" max-width="500" @click:outside="dialogs.alert.state = false">
      <v-card color="primary" dark>
        <v-card-title class="d-flex justify-space-between">
          Alert
          <v-btn icon @click="dialogs.alert.state = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <span v-text="dialogs.alert.message"></span>
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn @click="dialogs.alert.state = false">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<style scoped>
#virtual-scroll-table {
  max-height: 400px;
  overflow: auto;
}

.scrollable {
  max-height: 400px;
  overflow: auto;
}

.hidden {
  visibility: hidden;
}
</style>

<script>
import dayjs from "dayjs";
import axios from "axios";
import md5 from "crypto-js/md5";
import { isNumber, isNaN, isNull, isUndefined, filter, findIndex, map, union, sortBy } from "lodash";
import TranscriptEditor from "@/components/TranscriptEditor";
// import Alert from "@/components/Alert";

export default {
  name: "Captions",

  components: {
    TranscriptEditor,
    // Alert
  },

  props: ["bus", "blobServer", "showId", "ind", "entityId", "episodeId", "currentTime", "showInfo", "jwtToken", "jwtSource"],

  data: () => ({
    loading: false,
    ccLoading: false,
    speakersLoading: false,
    isDirty: false,
    search: null,
    sortVersions: true,
    versions: [],
    preferredVersion: {
      transcriptionId: null,
      version: null
    },
    selectedVersion: {
      transcriptionId: null,
      version: null
    },
    headers: [
      { text: "Start Time", value: "start", width: 90 },
      { text: "Speaker", value: "voice", width: 150 },
      { text: "Text", value: "text" },
      { text: "", value: "actions" },
    ],
    items: [],
    itemsTranscript: [],
    selectedRows: [],
    captions: null,
    start: 0,
    timeout: null,
    rowHeight: 24,
    perPage: 25,
    tableMode: false,
    findReplace: {
      find: '',
      replace: '',
      caseSensitive: false,
      results: [], // Array of {captionIndex, matchIndexes: [{start, end}]}
      currentMatchIndex: -1,
      highlight: {
        includeBefore: 20,
        includeAfter: 20
      },
    },
    menus: {
      find: false,
    },
    dialogs: {
      alert: {
        state: false,
        message: null,
      },
      transcript: {
        state: false,
      },
      speakerEditor: {
        state: false,
        speakerNames: [],
        speakerNamesEdited: [],
        speakerNamesCustom: [],
        annotations: {
          hosts: [],
          guests: []
        }
      },
      ccEditor: {
        state: false,
        line: null,
      },
      confirmDelete: {
        state: false,
        item: null,
      },
    },
    editTimer: null,
    editTimeout: 60 * 60 * 1000, // 1 hour in milliseconds
    autoSaveInterval: null,
    autoSaveTime: 30 * 60 * 1000, // Auto-save every 30 minutes
  }),

  watch: {
    async jwtToken() {
      if (this.jwtToken) {
        await this.loadVersions();
        await this.loadCaptions();
      }
    },

    itemsTranscript() {
      // take the grouped transcript view and flatten them again
      const items = [];
      for (let exchange of this.itemsTranscript) {
        for (let item of exchange) {
          items.push(item);
        }
      }
      this.items = items;

      // regenerate the VTT file for the editor to load by URL
      this.regenerateVTT();
    },

    // currentTime() {
    //   const foundIndex = findIndex(this.items, (o) => {
    //     return (
    //       this.currentTime >= Math.round(o.start / 1000) &&
    //       this.currentTime < Math.round(o.end / 1000)
    //     );
    //   });
    //   if (foundIndex !== -1) this.selectedRows = [this.items[foundIndex]]; //for table view
    // },

    "findReplace.find"() {
      this.findResults();
    },

    "findReplace.caseSensitive"() {
      this.findResults();
    }
  },

  computed: {
    dayjs() {
      return dayjs;
    },

    guestsAndHosts() {
      return union(['-- Custom --'], this.dialogs.speakerEditor.annotations.hosts || [], this.dialogs.speakerEditor.annotations.guests || []).sort();
    },

    itemsLimited() {
      return this.items.slice(this.start, this.perPage + this.start);
    },

    startHeight() {
      return this.start * this.rowHeight - 32;
    },

    endHeight() {
      return this.rowHeight * (this.items.length - this.start);
    },

    speakerNames() {
      const voices = this.items.map(o => {
        return { 
          text: isNaN(parseInt(o.voice)) ? this.getSpeaker(o.voice) : o.voice, 
          value: o.voice,
          // Add original index to maintain order
          originalIndex: isNaN(parseInt(o.voice)) ? Infinity : parseInt(o.voice)
        };
      });

      // Get distinct voices while preserving original order
      let result = [];
      const map = new Map();
      for (const voice of voices) {
        if (!map.has(voice.value)) {
          map.set(voice.value, true);
          result.push(voice);
        }
      }

      // Sort by original index first (for numbered speakers), then alphabetically for custom names
      result = sortBy(result, [
        o => o.originalIndex,
        o => o.text
      ]);

      return result.map(({text, value}) => ({text, value}));
    },

    episodeGUID() {
      return this.md5toUniqueId(md5(`episodeVersion${this.episodeId}`).toString());
    },

    showCCPublish() {
      return !!this.selectedVersion.transcriptionId && (this.selectedVersion.transcriptionId !== this.preferredVersion.transcriptionId || this.selectedVersion.version !== this.preferredVersion.version);
    },

    showCCUnpublish() {
      return this.$vuetify.theme.themes.name !== 'simplecast' && !this.showCCPublish;
    },

    hasMatches() {
      return this.findReplace.results.length > 0;
    },
  },

  methods: {
    checkUnauthorized(err) {
      if (err.response && err.response.status) {
        if (err.response.status === 401) {
          this.$router.push({ name: 'Login', query: { redirect: window.location.href } });
        }
      }
    },

    async loadVersions() {
      try {
        const response = await axios.get(`/entity/${this.entityId || window.__env.ccEntityPrefix + this.episodeGUID}/transcriptions/detailsWithPreferredVersion`, {
          headers: {
            Authorization: "Bearer " + this.jwtToken,
            "X-Source": this.jwtSource
          }
        });

        //augment the transcriptions and versions with protobuf metadata
        for (const transcription of response.data.details) {
          for (const version of transcription.versions) {
            try {
              if (version.data) {
                const proto = await axios.post('/proto-decode', {
                  message: version.data
                }, {
                  headers: {
                    Authorization: "Bearer " + this.jwtToken,
                    "X-Source": this.jwtSource
                  }
                });
                version.proto = proto.data.decoded.data;
              }
            } catch (err) {
              // console.error(err);
            }
          }
        }

        // sort versions by version number
        if (this.sortVersions) {
          for (const transcript of response.data.details) {
            transcript.versions = sortBy(transcript.versions, o => {
              return parseInt(o.versionNumber) || 0;
            });
          }
        }

        this.versions = response.data.details;

        // set the preferredVersion
        this.preferredVersion = {
          transcriptionId: response.data.preferredVersion?.transcriptionId || null,
          version: isNumber(response.data.preferredVersion?.versionNumber) ? response.data.preferredVersion?.versionNumber : null
        };

        this.$emit('hasCaptions', true);
      } catch (err) {
        this.checkUnauthorized(err);
        console.error(err);

        this.$emit('hasCaptions', false);
      }
    },

    async loadCaptions() {
      try {
        this.loading = true;

        // determine current selectedVersion from API if not specified
        if (!this.selectedVersion.transcriptionId || isNaN(this.selectedVersion.version)) {
          const responsePV = await axios.get(`/entity/${this.entityId || window.__env.ccEntityPrefix + this.episodeGUID}/preferredVersion`, {
            headers: {
              Authorization: "Bearer " + this.jwtToken,
              "X-Source": this.jwtSource
            },
            validateStatus: status => {
              return status >= 200 && status < 300 || status === 404;
            },
          });

          if (!isUndefined(responsePV.data.transcriptionId) && !isUndefined(responsePV.data.versionNumber)) {
            this.selectedVersion = {
              transcriptionId: responsePV.data.transcriptionId,
              version: responsePV.data.versionNumber
            };

            this.preferredVersion = {
              transcriptionId: responsePV.data.transcriptionId,
              version: responsePV.data.versionNumber
            };
          } else {
            // try to get the latest transcription and latest version if we don't have a preferred
            // console.log('No preferred version found, trying another` way...')

            //sort this.versions by transcription.created DESC
            const sortedTranscriptions = sortBy(this.versions, o => o.transcription.created).reverse();

            if (sortedTranscriptions[0]) {
              // console.log(`Found the most recent transcript: ${sortedTranscriptions[0].transcription.transcriptionId}`);

              //sort versions array by highest versionNumber
              const sortedVersions = sortBy(sortedTranscriptions[0].versions, 'versionNumber').reverse();
              const initialVersion = sortedVersions[0];

              if (initialVersion) {
                // console.log(`Found the most recent version: ${initialVersion.versionNumber}`);
                this.selectedVersion = {
                  transcriptionId: initialVersion.transcriptionId,
                  version: initialVersion.versionNumber
                };
              }
            } else {
              // console.log('Still unable to find a preferred version :(');
            }
          }
        }

        //bail out if these are null/undefined
        if (!this.selectedVersion.transcriptionId || isNull(this.selectedVersion.version) || isUndefined(this.selectedVersion.version)) {
          this.loading = false;
          return;
        }

        const response = await axios.get(`/captions/json/${this.entityId || window.__env.ccEntityPrefix + this.episodeGUID}/transcriptions/${this.selectedVersion.transcriptionId}/version/${this.selectedVersion.version}`, {
          headers: {
            Authorization: "Bearer " + this.jwtToken,
            "X-Source": this.jwtSource
          }
        });

        if (!response.data.success) throw new Error('Error from API');

        // pull out the data property in each cue
        this.items = map(filter(response.data.transcript, { type: "cue" }), 'data');

        this.generateTranscript();

        // this.$emit('hasCaptions', true);
        this.loading = false;

        //if we're loading a non-preferred version, consider it dirty so the user can click the CC Approve button to make it preferred
        // this.isDirty = (!this.selectedVersion.transcriptionId || isNaN(this.selectedVersion.version)) ? false : true;
        //@todo fix isDirty
      } catch (err) {
        this.checkUnauthorized(err);
        console.error(err);
        // this.$emit('hasCaptions', false);
        this.loading = false;
        this.isDirty = false; //?
      }
    },

    async loadAnnotations() {
      try {
        const { data } = await axios.get(`/mddb/v3/episode?episodeShortId=${parseInt(this.showInfo.episode.shortId, 16) || ''}&showShortId=${parseInt(this.showInfo.shortId, 16)}`, {
          headers: {
            Authorization: "Bearer " + this.jwtToken,
            "X-Source": this.jwtSource
          }
        });

        const hosts = union(data.show.hosts || [], data.episode.hosts || []).sort();
        const guests = union(data.show.guests || [], data.episode.guests || []).sort();

        this.dialogs.speakerEditor.annotations.hosts = hosts;
        this.dialogs.speakerEditor.annotations.guests = guests;
      } catch (err) {
        this.checkUnauthorized(err);
        console.error(err);
      }
    },

    async ccApprove() {
      try {
        this.ccLoading = true;

        //save first, if needed
        if (this.isDirty) await this.ccSave(true);

        await axios.post(`/entity/${this.entityId || window.__env.ccEntityPrefix + this.episodeGUID}/activate?showId=${this.showId}&episodeId=${this.episodeId}`, {
          typedEntityId: `${this.entityId || window.__env.ccEntityPrefix + this.episodeGUID}`,
          transcriptionId: this.selectedVersion.transcriptionId,
          version: this.selectedVersion.version,
          // activeVersionDescription: `Approved by xxx on ${dayjs().format('MMM D, YYYY hh:mm a')}`, //this gets overwritten by the API server to grab user info
          inactive: false
        }, {
          headers: {
            Authorization: "Bearer " + this.jwtToken,
            "X-Source": this.jwtSource
          }
        });

        this.ccLoading = false;

        // this.$nextTick(async () => {
        await this.loadVersions();
        await this.loadCaptions();
        // });

        this.alert('This transcript has been published successfully as the preferred version.');
      } catch (err) {
        this.checkUnauthorized(err);
        console.error(err);

        this.ccLoading = false;

        // this.$nextTick(async () => {
        await this.loadVersions();
        await this.loadCaptions();
        // });
      }
    },

    async ccReject() {
      try {
        this.ccLoading = true;

        await axios.post(`/entity/${this.entityId || window.__env.ccEntityPrefix + this.episodeGUID}/transcriptions/${this.selectedVersion.transcriptionId}?showId=${this.showId}&episodeId=${this.episodeId}`, {
          typedEntityId: `${this.entityId || window.__env.ccEntityPrefix + this.episodeGUID}`,
          transcriptionId: this.selectedVersion.transcriptionId,
          version: this.selectedVersion.version,
          // activeVersionDescription: `Approved by xxx on ${dayjs().format('MMM D, YYYY hh:mm a')}`, //this gets overwritten by the API server to grab user info
          inactive: true
        }, {
          headers: {
            Authorization: "Bearer " + this.jwtToken,
            "X-Source": this.jwtSource
          }
        });

        this.ccLoading = false;

        this.loadVersions();
        this.loadCaptions();

        this.alert('This transcript has been successfully unpublished.');
      } catch (err) {
        this.checkUnauthorized(err);
        console.error(err);

        this.ccLoading = false;

        this.loadVersions();
        this.loadCaptions();
      }
    },

    async ccSave(suppress) {
      try {
        const response = await axios.put(`/entity/${this.entityId || window.__env.ccEntityPrefix + this.episodeGUID}/transcriptions/${this.selectedVersion.transcriptionId}`, {
          captions: this.items,
        }, {
          headers: {
            Authorization: "Bearer " + this.jwtToken,
            "X-Source": this.jwtSource
          }
        });

        if (!suppress) this.alert('Closed Captions have been saved successfully.');

        //advance to select this new version and mark the captions clean
        this.selectedVersion = {
          transcriptionId: response.data.transcriptionId,
          version: response.data.versionNumber
        };
        this.isDirty = false;

        if (!suppress) await this.loadVersions();
        if (!suppress) await this.loadCaptions();
      } catch (err) {
        this.checkUnauthorized(err);
        console.error(err);
        this.alert('An error occurred while attempting to save Closed Captions.');

        if (!suppress) this.loadVersions();
        if (!suppress) this.loadCaptions();
      }
    },

    async selectVersion(transcriptionId, version) {
      //check if isDirty and prompt user to confirm they want to discard changes
      const confirm = this.isDirty ? window.confirm('Do you want to discard your currently pending changes?') : true;
      if (confirm) {
        this.selectedVersion = {
          transcriptionId,
          version
        };
        await this.loadCaptions();
      }
    },

    generateTranscript() {
      let lastSpeaker;
      this.itemsTranscript = this.groupFragments(this.items.map(o => {
        o.voice = o.voice ? o.voice : lastSpeaker ? lastSpeaker : null;
        lastSpeaker = o.voice;
        return o;
      }));
    },

    groupFragments(lines) {
      const groupedLines = [];
      let currentLines = [];
      let lastVoice;
      for (const line of lines) {
        if (lastVoice && lastVoice !== line.voice) {
          groupedLines.push(currentLines);
          currentLines = [];
        }
        currentLines.push(line);
        lastVoice = line.voice;
      }

      //make sure we don't chop off the last speaker by not pushing the end of the buffer at the end of the loop
      groupedLines.push(currentLines);

      //if they all grouped under one voice, we need to return them
      if (groupedLines.length === 0) groupedLines.push(currentLines);

      return groupedLines;
    },

    async openSpeakerEditor() {
      this.speakersLoading = true;

      //only load the speakers from MDDB once
      if (this.dialogs.speakerEditor.annotations.hosts.length === 0 && 
          this.dialogs.speakerEditor.annotations.guests.length === 0) {
        await this.loadAnnotations();
      }

      this.dialogs.speakerEditor.speakerNames = this.speakerNames;

      // preload the speakerNamesEdited array with the current speakers
      for (const [index, editedSpeakerName] of this.dialogs.speakerEditor.speakerNames.entries()) {
        const isDefaultSpeaker = /^Speaker \d+$/.test(editedSpeakerName.text);
        
        if (isDefaultSpeaker) {
          // Keep default speaker names as is, don't treat as custom
          this.dialogs.speakerEditor.speakerNamesEdited[index] = editedSpeakerName.text;
          this.dialogs.speakerEditor.speakerNamesCustom[index] = '';
        }
        else if (this.guestsAndHosts.includes(editedSpeakerName.text)) {
          // Known guest/host
          this.dialogs.speakerEditor.speakerNamesEdited[index] = editedSpeakerName.text;
          this.dialogs.speakerEditor.speakerNamesCustom[index] = '';
        } 
        else {
          // Actually custom name
          this.dialogs.speakerEditor.speakerNamesEdited[index] = '-- Custom --';
          this.dialogs.speakerEditor.speakerNamesCustom[index] = editedSpeakerName.text;
        }
      }

      this.speakersLoading = false;
      this.dialogs.speakerEditor.state = true;
    },

    saveSpeakers() {
      const speakerNamesToUpdate = {};
      for (const [index, speakerNameEdited] of this.dialogs.speakerEditor.speakerNamesEdited.entries()) {
        if (this.dialogs.speakerEditor.speakerNames[index].value && 
            this.dialogs.speakerEditor.speakerNames[index].text) {
          const originalValue = this.dialogs.speakerEditor.speakerNames[index].value;
          
          if (speakerNameEdited === '-- Custom --') {
            const customName = this.dialogs.speakerEditor.speakerNamesCustom[index];
            speakerNamesToUpdate[originalValue] = customName && customName.trim() !== '' ? 
              customName : `Speaker ${parseInt(index) + 1}`;
          } else {
            speakerNamesToUpdate[originalValue] = speakerNameEdited;
          }
        }
      }

      this.setNewSpeakers(speakerNamesToUpdate);
      this.dialogs.speakerEditor.state = false;

      this.regenerateVTT();
      this.isDirty = true;
    },

    setNewSpeakers(newSpeakers) {
      //update the speaker name for all captions
      for (const [index, speaker] of Object.entries(newSpeakers)) {
        if (!speaker) continue;

        const foundCaptions = filter(this.items, { voice: isNumber(index) ? index.toString() : index });
        foundCaptions.map(o => {
          o.voice = speaker;
          return o;
        });
      }
    },

    saveItem() {
      this.regenerateVTT();
      this.isDirty = true;
    },

    openCCEditor(line) {
      this.dialogs.ccEditor.lines = [line];
      this.dialogs.ccEditor.state = true;
    },

    openCCEditorMultiple() {
      const lines = [];
      this.dialogs.ccEditor.lines = lines;
      this.dialogs.ccEditor.state = true;
    },

    deleteItemConfirm(item) {
      this.dialogs.confirmDelete.item = item;
      this.dialogs.confirmDelete.state = true;
    },

    deleteItem(item) {
      // delete row from captions
      const foundIndex = findIndex(this.items, { start: item.start });
      if (foundIndex !== -1) this.items.splice(foundIndex, 1);

      // regenerate the transcript version from the updated captions
      this.generateTranscript();

      // regenerate the VTT file for the editor to load by URL
      this.regenerateVTT();
      this.isDirty = true;

      this.dialogs.confirmDelete.state = false;
    },

    async regenerateVTT() {
      const response = await axios.post(
        `/captions/generate/vtt/${this.entityId || this.episodeId}`,
        {
          captions: this.items,
        },
        {
          headers: {
            Authorization: "Bearer " + this.jwtToken,
            "X-Source": this.jwtSource
          },
        }
      );

      //update VTT track
      this.$emit("updateCaptions", response.data.vttHandle);
    },

    transcriptDirtyChanged(isDirty) {
      this.isDirty = isDirty;
      if (isDirty) {
        this.startEditTimer();
      }
    },

    stripHTML(str) {
      if (typeof str !== "string") return str;
      const regex = /(<([^>]+)>)/gi;
      return str.replace(regex, "");
    },

    selectCaption(e, data) {
      this.seekToCaption(data.item);
    },

    seekToCaption(item) {
      this.$emit("seekreq", item.start / 1000);
    },

    async findResults() {
      const search = this.findReplace.find;
      if (!search || search.length === 0) {
        this.findReplace.results = [];
        this.findReplace.currentMatchIndex = -1;
        return;
      }

      try {
        // Create regex for search
        const flags = this.findReplace.caseSensitive ? 'g' : 'gi';
        const pattern = new RegExp(this.escapeRegExp(search), flags);

        // Search through all captions
        const results = [];
        let totalMatches = 0;
        
        for (const [captionIndex, caption] of this.items.entries()) {
          const text = caption.text;
          const matches = [];
          
          let match;
          while ((match = pattern.exec(text)) !== null) {
            matches.push({
              start: match.index,
              end: match.index + match[0].length,
              text: match[0],
              matchIndex: totalMatches++ // Add global match index
            });
          }

          if (matches.length > 0) {
            results.push({
              captionIndex,
              caption,
              matches
            });
          }
        }

        this.findReplace.results = results;
        this.findReplace.currentMatchIndex = results.length > 0 ? 0 : -1;
        
        // Scroll to first match if found
        if (results.length > 0) {
          this.scrollToMatch(results[0]);
        }
      } catch (err) {
        console.error('Error in findResults:', err);
      }
    },

    scrollToMatch(result) {
      // Scroll the transcript to show the matched caption
      this.$nextTick(() => {
        const element = document.querySelector(`[data-caption-index="${result.captionIndex}"]`);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      });
    },

    async replaceOne() {
      const currentResult = this.findReplace.results[this.findReplace.currentMatchIndex];
      if (!currentResult) return;

      try {
        const caption = this.items[currentResult.captionIndex];
        const match = currentResult.matches[0]; // Get first remaining match

        // Create new text with replacement
        const newText = caption.text.substring(0, match.start) + 
                       this.findReplace.replace + 
                       caption.text.substring(match.end);

        // Update caption
        caption.text = newText;

        // Remove the replaced match
        currentResult.matches.shift();

        // Adjust remaining match positions in this caption
        const lengthDiff = this.findReplace.replace.length - match.text.length;
        for (const remainingMatch of currentResult.matches) {
          remainingMatch.start += lengthDiff;
          remainingMatch.end += lengthDiff;
        }

        // Remove this result if no more matches
        if (currentResult.matches.length === 0) {
          this.findReplace.results.splice(this.findReplace.currentMatchIndex, 1);
        }

        // Move to next match
        if (this.findReplace.results.length === 0) {
          this.findReplace.currentMatchIndex = -1;
        } else if (this.findReplace.currentMatchIndex >= this.findReplace.results.length) {
          this.findReplace.currentMatchIndex = 0;
        }

        this.isDirty = true;
        await this.regenerateVTT();

      } catch (err) {
        console.error('Error in replaceOne:', err);
      }
    },

    async replaceAll() {
      const confirmAll = confirm(
        `Are you sure you want to replace all ${this.getTotalMatchCount()} occurrences?`
      );

      if (confirmAll) {
        try {
          for (const result of this.findReplace.results) {
            const caption = this.items[result.captionIndex];
            let offset = 0;

            for (const match of result.matches) {
              const adjustedStart = match.start - offset;
              const adjustedEnd = match.end - offset;
              
              const newText = caption.text.substring(0, adjustedStart) + 
                             this.findReplace.replace + 
                             caption.text.substring(adjustedEnd);

              offset += match.end - match.start - this.findReplace.replace.length;
              caption.text = newText;
            }
          }

          this.findReplace.results = [];
          this.findReplace.currentMatchIndex = -1;
          this.isDirty = true;
          await this.regenerateVTT();

        } catch (err) {
          console.error('Error in replaceAll:', err);
          this.alert('An error occurred while replacing text');
        }
      }
    },

    getTotalMatchCount() {
      return this.findReplace.results.reduce((total, result) => {
        return total + result.matches.length;
      }, 0);
    },

    highlightMatches(result) {
      let text = result.caption.text;
      
      // Sort matches in reverse order to avoid position changes
      const sortedMatches = [...result.matches].sort((a, b) => b.start - a.start);
      
      // Wrap each match in highlighting span
      for (const match of sortedMatches) {
        text = text.substring(0, match.start) +
              `<span class="yellow black--text">${match.text}</span>` +
              text.substring(match.end);
      }
      
      return text;
    },

    getTimeCode(ms) {
      return dayjs.duration(ms, "milliseconds").format("HH:mm:ss");
    },

    getSpeaker(voice) {
      return !isNaN(parseInt(voice)) ? "Speaker " + (parseInt(voice) + 1) : voice;
    },

    alert(message) {
      this.dialogs.alert.message = message;
      this.dialogs.alert.state = true;
    },

    md5toUniqueId(md5Hash) {
      if (typeof md5Hash !== 'string') return;
      return (
        md5Hash.substring(0, 8) +
        '-' +
        md5Hash.substring(8, 12) +
        '-' +
        md5Hash.substring(12, 16) +
        '-' +
        md5Hash.substring(16, 20) +
        '-' +
        md5Hash.substring(20)
      ).toLowerCase();
    },

    escapeRegExp(string) {
      return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
    },

    onScroll(e) {
      // debounce if scrolling fast
      this.timeout && clearTimeout(this.timeout);

      this.timeout = setTimeout(() => {
        const { scrollTop } = e.target;
        const rows = Math.ceil(scrollTop / this.rowHeight);

        this.start =
          rows + this.perPage > this.items.length
            ? this.items.length - this.perPage
            : rows;

        this.$nextTick(() => {
          e.target.scrollTop = scrollTop;
        });
      }, 20);
    },

    startEditTimer() {
      // Clear any existing timer
      if (this.editTimer) {
        clearTimeout(this.editTimer);
      }
      
      // Set new timer
      this.editTimer = setTimeout(() => {
        if (this.isDirty) {
          this.alert('You have been editing for over an hour. Please save your changes to avoid losing work.');
        }
      }, this.editTimeout);
    },

    setupAutoSave() {
      // Clear any existing interval
      if (this.autoSaveInterval) {
        clearInterval(this.autoSaveInterval);
      }

      // Set up auto-save interval
      this.autoSaveInterval = setInterval(() => {
        if (this.isDirty) {
          this.ccSave(true); // Pass true to suppress save notification
        }
      }, this.autoSaveTime);
    },

    unescapeEntities(text) {
      return text
        .replace(/&lt;/g, '<')
        .replace(/&gt;/g, '>')
        .replace(/&amp;/g, '&');
    },

    handleFindReplaceKeydown(e) {
      if (this.menus.find) {
        if (e.key === 'Enter' && !e.shiftKey) {
          this.replaceOne();
        } else if (e.key === 'Enter' && e.shiftKey) {
          this.replaceAll();
        } else if (e.key === 'F3' || (e.key === 'g' && e.ctrlKey)) {
          e.preventDefault();
          this.nextMatch();
        }
      }
    },

    nextMatch() {
      if (this.findReplace.results.length === 0) return;
      
      this.findReplace.currentMatchIndex = (this.findReplace.currentMatchIndex + 1) % this.findReplace.results.length;
      this.scrollToMatch(this.findReplace.results[this.findReplace.currentMatchIndex]);
    },

    getMatchCountText() {
      return this.getTotalMatchCount() + ' occurrences';
    },

    selectMatch(index) {
      this.findReplace.currentMatchIndex = index;
      if (this.findReplace.results[index]) {
        this.scrollToMatch(this.findReplace.results[index]);
      }
    },
  },

  created() {
    this.bus.$on('ccApprove', () => {
      this.ccApprove();
    });
  },

  mounted() {
    axios.defaults.baseURL = window.__env.baseURL;

    //moved init calls to the jwtToken watch function, because we have a race condition where the token isn't always ready by this time
    if (this.jwtToken) {
      this.loadVersions();
      this.loadCaptions();
    }
    this.setupAutoSave();
  },

  beforeDestroy() {
    if (this.editTimer) {
      clearTimeout(this.editTimer);
    }
    if (this.autoSaveInterval) {
      clearInterval(this.autoSaveInterval);
    }
    
    if (this.isDirty) {
      const shouldSave = window.confirm('You have unsaved changes. Would you like to save them before leaving?');
      if (shouldSave) {
        this.ccSave();
      }
    }
  },
};
</script>
